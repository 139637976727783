@import url(https://fonts.googleapis.com/css?family=Montserrat&display=swap);
#nav-bar-wrapper{height:100%}#nav-bar-wrapper .nav-bar-item{height:100%;padding:0px 1.25rem !important;display:inline-flex;flex-direction:row;align-items:center;color:#fff}#nav-bar-wrapper .nav-bar-item.selected{background-color:white;color:#00A4EF}

#link-panel{position:absolute;height:250px;width:13.7rem;z-index:99;border-radius:6px;display:flex;flex-direction:column;justify-content:center;align-items:center}#link-panel.disappear{-webkit-animation-name:slide-up;animation-name:slide-up;-webkit-animation-duration:.5s;animation-duration:.5s;-webkit-animation-fill-mode:forwards;animation-fill-mode:forwards;-webkit-animation-timing-function:ease-in;animation-timing-function:ease-in}#link-panel.show{-webkit-animation-name:slide-down;animation-name:slide-down;-webkit-animation-duration:.5s;animation-duration:.5s;-webkit-animation-fill-mode:forwards;animation-fill-mode:forwards;-webkit-animation-timing-function:ease-out;animation-timing-function:ease-out}@-webkit-keyframes slide-down{from{top:-250px}to{top:7vh}}@keyframes slide-down{from{top:-250px}to{top:7vh}}@-webkit-keyframes slide-up{from{top:7vh}to{top:-250px}}@keyframes slide-up{from{top:7vh}to{top:-250px}}#link-panel .link{z-index:99}#link-panel .link .link-icon{color:#7FBA00}



#app-bar{height:7vh;position:fixed;top:0;width:100%;display:flex;flex-direction:row;align-items:center;justify-content:space-between;background-color:#00A4EF;padding:0px !important;padding-left:1.25rem !important;box-sizing:border-box;z-index:100}#expand-more-and-less-icon{position:fixed;top:7vh;width:1.5rem;height:1.5rem;-webkit-transform:translate(13rem, -0.8rem);transform:translate(13rem, -0.8rem);background-color:#fff;border-radius:50px;fill:#00A4EF;border:0.1px solid rgba(0,0,0,0.7);z-index:101}#expand-more-and-less-icon:hover{box-shadow:0 0 0.3rem 0.1rem rgba(0,0,0,0.3);cursor:pointer}

.job-card-panel{margin:1rem 0px !important;border:1px solid #d6d3db;box-shadow:0 2px 4px 0 rgba(0,0,0,0.05) !important;border-radius:6px !important}.job-card-panel .company-icon{width:70%;height:auto;min-width:90px}@media (max-width: 960px){.company-icon{width:90px !important}.job-summary-wrapper{text-align:center}}

.headshot-avatar{min-width:250px;min-height:250px;width:50%;height:50%;margin:auto;border:1px solid black}@media (max-width: 1060px){.headshot-avatar{min-width:225px;min-height:225px}}

.education-item-card{width:50%;min-width:500px;margin:1rem auto !important;border:1px solid #d6d3db;box-shadow:0 2px 4px 0 rgba(0,0,0,0.05) !important;border-radius:6px !important}.education-item-card .education-item-inner-wrapper{padding:12px 24px !important}.education-item-card .institution-icon{width:70%;height:auto;min-width:90px}.education-item-tooltip{border:1px solid #d6d3db;box-shadow:0 2px 4px 0 rgba(0,0,0,0.05) !important;border-radius:6px !important;background-color:#ffd21c !important;opacity:0.9 !important;color:black !important;font-weight:600 !important;margin:2px !important;text-align:justify}@media (max-width: 960px){.institution-icon{width:90px !important}.education-item-card{min-width:25rem}}

body{margin:0px !important}.no-text-decoration{text-decoration:none !important}main.main-content-wrapper{min-height:60vh;width:80%;margin:auto;margin-top:80px !important;margin-bottom:100px !important;display:flex;flex-direction:column;justify-content:center}@media (max-width: 655px){main.main-content-wrapper{margin-top:120px !important}}@media (max-width: 355px){main.main-content-wrapper{margin-top:230px !important}}

