// Color scheme
$color-primary: #00A4EF;
$color-secondary: #FFFFFF;
$color-green: #7FBA00;
$color-beige: #CCCCCC;
$color-purple: #501F3A;

// Spacing
$spacing-small: 0.5rem;
$spacing-1: 0.75rem;
$spacing-2: 1rem;
$spacing-3: 1.25rem;
$spacing-4: 1.5rem;

// Static sizes:
$nav-bar-height: 40px;
$app-bar-height: 7vh;


// z-indices
$app-bar-z: 100;
$app-bar-expand-icon-z: 101;
$link-panel-z: 99;

// animations
$link-panel-animation-duration-seconds: 0.5s;
$link-panel-animation-duration-milliseconds: 500;

// element specifics
$card-border-radius: 6px;


// Font
$font-family: Montserrat, sans-serif;

:export {
  colorPrimary: $color-primary;
  colorSecondary: $color-secondary;

  linkPanelAnimationDurationMilliseconds: $link-panel-animation-duration-milliseconds;
}
/* empty */

@import url("https://fonts.googleapis.com/css?family=Montserrat&display=swap");

$nav-bar-height: 80px;

body {
  margin: 0px !important;
}

.no-text-decoration {
  text-decoration: none !important;
}

main.main-content-wrapper {
  min-height: 60vh;
  width: 80%;
  margin: auto;
  margin-top: $nav-bar-height !important;
  margin-bottom: 100px !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

@media (max-width: 655px) {
  main.main-content-wrapper {
    margin-top: 120px !important;
  }
}

@media (max-width: 355px) {
  main.main-content-wrapper {
    margin-top: 230px !important;
  }
}